export default function reducer(state={
    list:[],
    activePage:{
        id:0,
        location:"",
        title:"",
        description:"",
        seo_title:"",
        seo_description:"",
        seo_url:"",
        full_seo_url:"",
        image_alt:"",
        activities:[],
        image_name:null,
        updated:false,
        tags:[]
    }
},action){
    switch(action.type){
        case "STORE_LOCATION_PAGES":{
            return{...state,list:action.pages}
        }
        case "CLEAR_LOCATION_PAGES":{
            return{...state,           
                    activePage:{
                        id:0,
                        location:"",
                        title:"",
                        description:"",
                        seo_title:"",
                        seo_description:"",
                        seo_url:"",
                        full_seo_url:"",
                        image_alt:"",
                        activities:[],
                        image_name:null,
                        updated:false,
                        tags:[]
                    }
            }
        }
        case "STORE_ACTIVE_PAGE":{
            return{...state,activePage:{
                ...state.activeMessage,
                id:action.p.id,
                location:action.p.location,
                title:action.p.title,
                description:action.p.description,
                seo_title:action.p.seo_title,
                seo_description:action.p.seo_description,
                seo_url:action.p.seo_url,
                image_alt:action.p.image_alt,
                activities:action.p.activities,
                image_name:action.p.image_name,
                full_seo_url:action.p.full_seo_url,
                updated:true,
                tags:action.p.tags
            }}
        }
        case "LOCK_ACTIVE_MESSAGE":{
            return{...state,activeMessage:{
                ...state.activeMessage,
                updated:false
            }}
        }
    }

    return state;
}
