import React, { Component, Fragment } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Drawer from './Common/Drawer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded'
import TablePagination from '@material-ui/core/TablePagination'
import { compose } from 'redux'
import { connect } from 'react-redux'
import AppBar from './Common/MainAppBar'
import { Link } from 'react-router-dom'
import SearchSelect from '../Components/TagInput'
import { MyActivities, DeleteActivity, TrashTour, featuredToursList, featuredUpdating } from '../Actions/ActivityActions'
import Alert from './Common/Alert'
import { GetSuppliersStausFilter } from '../Actions/SupplierActions'
import Checkbox from "../Components/Checkbox"

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },



  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,

  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,


  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,

  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,

  textAlignCenter: {
    textAlign: 'center'
  }


});


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Draft" } //gray
    case 1: { return "Approved" } //green
    case 3: { return "In review" } //yellow
    case 4: { return "Banned" }// red 
    case 2: { return "Rejected" }// black
    case 5: { return "Trashed" }// black
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } } //green #4CAF50                  
    case 3: { return { backgroundColor: '#FFEB3B', color: '#000' } } //yellow #FFEB3B
    case 4: { return { backgroundColor: '#f44336', color: '#FFF' } } //red #f44336
    case 2: { return { backgroundColor: '#1a1818', color: '#FFF' } } //black #f44336
    case 5: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    ActivitiesList: state.Activities.list,
    suppliers: state.Suppliers.list,
    featuredUpdate: state.Activities.featured
  }

}



const mapDispatchToProps = dispatch => ({
  MyActivities: (ttype, tsts, keyw, supplier, page, perPage) => dispatch(MyActivities(ttype, tsts, keyw, supplier, page, perPage)),
  DeleteActivity: (activityID, ttype, tsts, keyw) => dispatch(DeleteActivity(activityID, ttype, tsts, keyw)),
  TrashTour: (data) => dispatch(TrashTour(data)),
  GetSuppliers: (gStatus) => dispatch(GetSuppliersStausFilter(gStatus)),
  featuredToursList: (type, aktivity) => dispatch(featuredToursList(type, aktivity)),
  featuredUpdating: (bool) => dispatch(featuredUpdating(bool)),
});



class Tours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
      keyword: "",
      tourtype: "0",
      tourstatus: "3",
      supplier: "",
      page: 0,
      per_page: 10,
      featured: false
    };

    this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier, this.state.page, this.state.per_page);
    this.props.GetSuppliers('1,3');
  }

  componentDidMount() {
    document.body.style.background = "#eee";
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    }, () => this.searchTour());

  };

  handleTabChange = (tab) => {
    this.setState({ value: tab });
  };

  getStatus = (status) => {
    switch (status) {
      case 0: { return "Draft" } //gray
      case 1: { return "Approved" } //green
      case 3: { return "In review" } //yellow
      case 4: { return "Banned" }// red 
      case 2: { return "Rejected" }// black
    }
  }

  getTripType(type) {

    switch (type) {
      case 1: { return "Round tour" }
      case 2: { return "Day Tour" }
      case 3: { return "Activity" }
    }
  }

  getDuration(measure) {
    switch (measure) {
      case 1: { return "Days" }
      case 2: { return "Hours" }
      case 3: { return "Minutes" }
    }
  }

  selectkeyword = (e) => {
    this.setState({
      keyword: e.target.value
    }, () => {
      setTimeout(
        function () {
          this.searchTour();
        }
          .bind(this),
        1000
      );
    })
  }

  selectSupplier = (value) => {
    if (value === null) {
      this.setState({
        supplier: null
      }, () => this.searchTour());
    } else {
      this.setState({
        supplier: value.id
      }, () => this.searchTour());
    }

  }

  selecttourType = (e) => {
    this.setState({
      tourtype: e.target.value
    }, () => this.searchTour())
  }
  selecttourStatus = (e) => {
    this.setState({
      tourstatus: e.target.value
    }, () => this.searchTour())
  }


  searchTour = () => {

    this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, this.state.page, this.state.per_page);
  }


  handleDeleteActivity = (aid) => {
    this.props.TrashTour({ id: aid, status: 5, remark: '', title: 'Move tour to trash', ttype: this.state.tourtype, tsts: this.state.tourstatus, keyw: this.state.keyword, supplier: this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, page: this.state.page, perPage: this.state.per_page });
    //this.props.DeleteActivity(aid,this.state.tourtype, this.state.tourstatus, this.state.keyword);
  }

  handleChangeRowsPerPage = event => {
    this.setState({
      page: 0,
      per_page: +event.target.value
    }, () => this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, this.state.page, this.state.per_page));
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage + 1,
    }, () => this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword, this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier, this.state.page, this.state.per_page));
  };

  updateFeatured = (check,id) => {
    this.setState({featured:true})
    this.props.featuredToursList( {"tour_id":id, "type":check}, {
      "tt":this.state.tourtype,
      "ts":this.state.tourstatus,
      "k":this.state.keyword,
      "supplier":this.state.supplier === null && this.state.supplier === undefined ? "" : this.state.supplier,
      "page":this.state.page,
      "perPage":this.state.per_page
    })
  }

  featuredUpdating = () => {
    this.setState({featured:false})
    this.props.featuredUpdating(null)
  }

  render() {

    const { classes } = this.props;

    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer currentPage="tours"></Drawer>

      <Alert />
      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>





        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: 'white', width: 500, fontWeight: 600, fontSize: 16 }}>Tours & Activities</TableCell>

            </TableRow>

          </TableBody>
        </Table>


        <Table>
          <TableBody>
            <TableRow>




              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 30 }}>


                  <div className="tab-pan">
                    <div className="tab-bok">
                      <div float='right'>
                        <Table align="right" style={{ width: "30%", textAlign: "right", alignContent: 'right', alignSelf: 'center', float: 'right' }} className={classes.table}  >

                          <tr align="right">
                            <td align="right" style={{ width: "30%" }}>

                              <FormControl className={classes.formControl}>
                                <TextField
                                  label='Product Name/ ID'
                                  value={this.state.keyword}
                                  onChange={this.selectkeyword}
                                  style={{ width: '180px' }}
                                >

                                </TextField>
                              </FormControl>

                            </td>

                            <td align="right" style={{ width: "20%" }} >

                              <FormControl className={classes.formControl}>
                                <InputLabel shrink htmlFor="status-native-simple">Tour type</InputLabel>
                                <Select
                                  native
                                  // value={this.state.tour_type}

                                  // onChange={this.handleChange('tour_type')}
                                  // onChange={this.handleChange('tour_type')}
                                  onChange={this.selecttourType}


                                  value={this.state.tourtype}
                                  inputProps={{
                                    name: 'tour_type',
                                    id: 'status-native-simple',
                                  }}
                                >
                                  <option value="">  All  </option>
                                  <option value="1">  Round Tour  </option>
                                  <option value="2">  Day Tour    </option>

                                </Select>
                              </FormControl>

                            </td>

                            <td alighn="right" style={{ width: "20%" }} >

                              <FormControl className={classes.formControl}>
                                <InputLabel shrink htmlFor="status-native-simple"> Status </InputLabel>
                                <Select
                                  native
                                  // value={this.state.status}

                                  // onChange={this.handleChange('status')}
                                  onChange={this.selecttourStatus}

                                  value={this.state.tourstatus}
                                  inputProps={{
                                    name: 'status',
                                    id: 'status-native-simple',
                                  }}
                                >

                                  <option value="" >All</option>
                                  <option value="0">Draft</option>
                                  <option value="1">Approved</option>
                                  <option value="2">Rejected</option>
                                  <option value="3">In Review</option>
                                  <option value="4">Banned</option>
                                  <option value="5">Trashed</option>



                                </Select>
                              </FormControl>

                            </td>

                            <td alighn="right" style={{ width: "20%" }} >
                              <FormControl style={{ width: 240 }}>

                                <SearchSelect
                                  options={this.props.suppliers}
                                  getOptionLabel={option => `${option.work_name}`}
                                  getOptionValue={option => `${option.id}`}
                                  value={this.state.supplier}
                                  placeholder="Supplier"
                                  onPickupChange={this.selectSupplier}
                                />

                              </FormControl>
                            </td>


                          </tr>


                        </Table>

                      </div>

                      <table style={{ width: '100%' }} >
                        <thead style={{ fontSize: 12 }}>
                          <tr>
                            <th style={{ width: "10%" }} className={classes.textAlignCenter}  >#</th>
                            <th style={{ width: "25%" }}>Tour Name</th>
                            <th style={{ width: "10%" }}>Supplier</th>
                            <th style={{ width: "13%" }} className={classes.textAlignCenter}  >Tour Type</th>
                            <th style={{ width: "14%" }} className={classes.textAlignCenter}>Duration</th>
                            <th style={{ width: "17%" }} className={classes.textAlignCenter}>Category</th>
                            <th style={{ width: "7%" }} className={classes.textAlignCenter}  >Status</th>
                            <th style={{ width: "2%" }}></th>
                            <th style={{ width: "2%", backgroundColor: "primary" }}></th>
                            <th style={{ width: "2%" }}></th>
                          </tr>

                        </thead>
                        <tbody style={{ fontSize: 12 }}>

                          {this.props.ActivitiesList !== null ? this.props.ActivitiesList.data.map((act, index) => (
                            <tr key={act.id}>
                              <td className={classes.textAlignCenter} >{parseInt(this.props.ActivitiesList.from) + index}</td>
                              <td >{act.title}  <br /> <small>TourID-{act.tour_id}</small> </td>
                              <td >{act.supplier.supplier_profile.work_name}<br /> <small> ID : {act.supplier.sid} </small> </td>
                              <td className={classes.textAlignCenter} >{this.getTripType(act.tour_type)}</td>
                              <td className={classes.textAlignCenter} >{act.duration_value} &nbsp;{this.getDuration(act.duration)}</td>
                              <td>{act.category[0] !== undefined ? act.category[0].category.name : null} {act.category.length > 1 ? (<em>+{act.category.length - 1} more</em>) : null} </td>
                              <td className={classes.textAlignCenter}  >{StatusChip(act.status)}</td>
                              <td className={classes.textAlignCenter}  > 
                                <Checkbox 
                                  id={act.id}
                                  checked={act.featured===1}
                                  featured={this.state.featured}
                                  featuredUpdate={this.props.featuredUpdate}
                                  featuredUpdating={this.featuredUpdating}
                                  updateFeatured={this.updateFeatured}/> 
                              </td>
                              <td className={classes.textAlignCenter}  >
                                <IconButton style={{ paddingBottom:0 }} size="small" component={Link} to={"/EditActivity/" + act.id} color="white"  >
                                  <VisibilityRoundedIcon />
                                </IconButton>
                                {act.seo_title === null ? (<span style={{ color: 'orange', position: "absolute", paddingLeft: 10 }} >�</span>) : null}
                              </td>
                              <td className={classes.textAlignCenter}  >
                                <IconButton onClick={() => this.handleDeleteActivity(act.id)} color="white" >
                                  <DeleteIcon className={classes.rightIcon} />
                                </IconButton>
                              </td>
                            </tr>
                          )) : null}
                        </tbody>
                      </table>

                      {this.props.ActivitiesList !== null ?
                        <TablePagination
                          component="div"
                          count={this.props.ActivitiesList.total}
                          rowsPerPage={this.props.ActivitiesList.per_page}
                          page={this.props.ActivitiesList.current_page - 1}
                          backIconButtonProps={{
                            'aria-label': 'previous page',
                          }}
                          nextIconButtonProps={{
                            'aria-label': 'next page',
                          }}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        /> : null}

                    </div>
                  </div>


                </Paper>

              </TableCell>

            </TableRow>

          </TableBody>
        </Table>
      </div>
      </div>

    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);