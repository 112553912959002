import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const CheckInputbox = (props) => {
    const [check, setCheck] = useState(props.checked)

    function handleChecked(event) {
        var check =event.target.checked?1:0
        setCheck(event.target.checked)
        props.updateFeatured(check,props.id)
    }
    if(props.featuredUpdate && props.featured) {
        props.featuredUpdating()
    }

    return (
        <Checkbox
            checked={check}
            onChange={handleChecked}
            value="checkedB"
            color="primary"
            style={{ color: 'orange' }}
            disabled={props.featured}
        />
    );

};

export default CheckInputbox;