export default function reducer(state = {
    list: null,
    featured: null,
    activeTour: {
        id: 0,
        title: "",
        tour_type: 0,
        duration: 0,
        duration_value: 0,
        categories: [],
        overview: "",
        adults_age_from: null,
        adults_age_to: null,
        children_age_from: null,
        children_age_to: null,
        infant_age_from: null,
        infant_age_to: null,
        inclusion: null,
        exclision: null,
        know: null,
        condtions: null,
        contact_number: null,
        images: new Array(),
        itinery: new Array(),
        pricing_for: null,
        schedules: new Array(),
        locationgroups: [],
        sale_type: null,
        cutoff_time_duration: 0,
        cutoff_time_measure: 0,
        cutoff_time_related: 0,
        updated: false,
        commission: 0,
        include_adults: 0,
        include_children: 0,
        include_infants: 0,
        cover_image: "",
        status: 0,
        know_policy: "",
        cancel_policy: "",
        cancel_custom: "",
        terms_accepted: 0,
        remarks: [],
        seo_title: "",
        seo_description: "",
        seo_url: "",
        tags: [],
        full_seo_url: null
    },
    knowPolicies: [],
    cancelPolicies: []
}, action) {
    switch (action.type) {
        case "STORE_ACTIVITIES": {
            return { ...state, list: action.list }
        }
        case "FEATURED_TOUR_UPDATE": {
            return { ...state, featured: action.bool }
        }
        case "STORE_ACTIVE_ACTIVITY": {
            return {
                ...state,
                activeTour: {
                    id: action.activity.id,
                    title: action.activity.title,
                    tour_type: action.activity.tour_type,
                    duration: action.activity.duration,
                    duration_value: action.activity.duration_value,
                    categories: action.activity.categories,
                    overview: action.activity.overview,
                    adults_age_from: action.activity.adults_age_from,
                    adults_age_to: action.activity.adults_age_to,
                    children_age_from: action.activity.children_age_from,
                    children_age_to: action.activity.children_age_to,
                    infant_age_from: action.activity.infant_age_from,
                    infant_age_to: action.activity.infant_age_to,
                    inclusion: action.activity.inclusion,
                    exclision: action.activity.exclision,
                    know: action.activity.know,
                    condtions: action.activity.condtions,
                    contact_number: action.activity.contact_number,
                    images: action.activity.images,
                    itinery: action.activity.itinery,
                    pricing_for: action.activity.pricing_for,
                    schedules: action.activity.schedules,
                    locationgroups: action.activity.locationgroups,
                    sale_type: action.activity.sale_type,
                    cutoff_time_duration: action.activity.cutoff_time_duration,
                    cutoff_time_measure: action.activity.cutoff_time_measure,
                    cutoff_time_related: action.activity.cutoff_time_related,
                    commission: action.activity.commission,
                    include_adults: action.activity.include_adults,
                    include_children: action.activity.include_children,
                    include_infants: action.activity.include_infants,
                    cover_image: action.activity.cover_image,
                    know_policy: action.activity.know_policy,
                    cancel_policy: action.activity.cancel_policy,
                    cancel_custom: action.activity.cancel_custom,
                    status: action.activity.status,
                    remarks: action.activity.remarks,
                    terms_accepted: action.activity.terms_accepted,
                    seo_title: action.activity.seo_title,
                    seo_description: action.activity.seo_description,
                    seo_url: action.activity.seo_url,
                    full_seo_url: action.activity.full_seo_url,
                    updated: true,
                    tags: action.activity.tags
                }
            }
        }
        case "LOCK_ACTIVE_ACTIVITY": {
            return {
                ...state,
                activeTour: {
                    updated: false,
                }
            }
        }
        case "LOADIND_END": {
            return { ...state, loading: false }
        }
        case "UPDATE_TOUR": {
            return { ...state, activeTour: action.tour }
        }
        case "STORE_POLICIES": {
            return { ...state, [action.policyType]: action.list }
        }
    }
    return state
}
