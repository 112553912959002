import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';

import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import { Link } from 'react-router-dom';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';
import './../activity/css/style.css';
import './../activity/css/css.css';

import FormControl from '@material-ui/core/FormControl';



import { SnackBar } from '../Actions/CommonActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';



import { fetchUser, updateUser, uploadWorkLogo, uploadPersonalLogo, lockUserData, uploadDocument } from '../Actions/AuthActions';
import { GetSupplier, UpdateStatus, UpdateCommision, LockActiveSupplier, UpdateBanned, UpdateFieldStatus, UpdateRemoveAttachment, UpdateField } from '../Actions/SupplierActions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { fetchCategories } from '../Actions/CategoryAction';

import Alert from './Common/Alert';

import NewStaticTextBox from '../Components/NewStaticTextBox';
import Newtextarea from '../Components/Newtextarea';
import Background from './../Img/p1.png';

const drawerWidth = 240;

const styles = theme => ({




  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});



const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    user: state.Auth.user,
    categories: state.Categories.categories,
    alertVarient: state.Common.alertVarient,
    userDataOpen: state.Auth.dataopen,

    activeSupplier: state.Suppliers.activeSupplier
  }
}

const mapDispatchToProps = dispatch => ({

  fetchCategories: () => dispatch(fetchCategories()),
  fetchUser: () => dispatch(fetchUser()),
  updateUser: (data) => dispatch(updateUser(data)),
  uploadWorkLogo: (file) => dispatch(uploadWorkLogo(file)),
  uploadPersonalLogo: (file) => dispatch(uploadPersonalLogo(file)),
  lockUserData: () => dispatch(lockUserData()),
  uploadDocument: (file, type) => dispatch(uploadDocument(file, type)),

  GetSupplier: (sid) => dispatch(GetSupplier(sid)),
  UpdateStatus: (status, sid) => dispatch(UpdateStatus(status, sid)),
  UpdateCommision: (rate, sid) => dispatch(UpdateCommision(rate, sid)),
  LockActiveSupplier: () => dispatch(LockActiveSupplier()),
  UpdateBanned: (till, sid) => dispatch(UpdateBanned(till, sid)),
  UpdateFieldStatus: (status, field, id) => dispatch(UpdateFieldStatus(status, field, id)),
  UpdateRemoveAttachment: (field, id) => dispatch(UpdateRemoveAttachment(field, id)),
  SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient)),
  UpdateField: (data) => dispatch(UpdateField(data))
});

class Account extends Component {

  constructor(props) {
    super(props);

    const { sid } = this.props.match.params;

    this.state = {
      id: 0,
      name: null,
      email: null,
      user_type: 1,
      status: 1,
      work_name: null,
      work_address: null,
      work_reg_number: null,
      work_fix_phone: null,
      work_mobile: null,
      work_avatar: null,
      contact_name: null,
      contact_NIC: null,
      contact_home_phone: null,
      contact_mobile_phone: null,
      contact_address: null,
      contact_avatar: null,
      br_copy: null,
      tb_licence_copy: null,
      ins_copy: null,
      billing_proof: "",
      categories: [],
      ban_user: false,
      banned_till: null,
      customcommision: false,
      commission_rate: null,
      work_name_review: false,
      work_address_review: false,
      work_reg_number_review: false,
      work_fix_phone_review: false,
      work_mobile_review: false,
      contact_name_review: false,
      contact_NIC_review: false,
      contact_home_phone_review: false,
      contact_mobile_phone_review: false,
      contact_address_review: false,
      bank_name_review: false,
      bank_branch_review: false,
      bank_account_review: false,
      bank_account_name_review: false,
      work_logo_review: false,
      contact_logo_review: false,
      br_copy_review: false,
      tb_licence_copy_review: false,
      ins_copy_review: false,
      billing_proof_review: false,
      bank_name: null,
      bank_branch: null,
      bank_account: null,
      bank_account_name: null,
      in_review_fields: [],
      rejected_fields: [],
      status: 0
    };


    this.timeout = null;
    this.props.fetchCategories();
    //this.props.fetchUser();

    this.props.GetSupplier(sid);

    this.handleCatChange = this.handleCatChange.bind(this);
  }





  static getDerivedStateFromProps(props, state) {

    console.log(props);

    if (props.activeSupplier.updated === true) {

      props.LockActiveSupplier();

      return {
        id: props.activeSupplier.id,
        work_name: props.activeSupplier.work_name,
        work_address: props.activeSupplier.work_address,
        work_reg_number: props.activeSupplier.work_reg_number,
        work_fix_phone: props.activeSupplier.work_fix_phone,
        work_mobile: props.activeSupplier.work_mobile,
        work_avatar: props.activeSupplier.work_avatar,
        contact_name: props.activeSupplier.contact_name,
        contact_NIC: props.activeSupplier.contact_NIC,
        contact_home_phone: props.activeSupplier.contact_home_phone,
        contact_mobile_phone: props.activeSupplier.contact_mobile_phone,
        contact_address: props.activeSupplier.contact_address,
        contact_avatar: props.activeSupplier.contact_avatar,
        br_copy: props.activeSupplier.br_copy,
        tb_licence_copy: props.activeSupplier.tb_licence_copy,
        ins_copy: props.activeSupplier.ins_copy,
        billing_proof: props.activeSupplier.billing_proof,
        categories: props.activeSupplier.categories,
        ban_user: props.activeSupplier.banned_till !== null,
        banned_till: props.activeSupplier.banned_till,
        customcommision: props.activeSupplier.sales_commission,
        commission_rate: props.activeSupplier.sales_commission_rate,
        email_verified: props.activeSupplier.email_verified,
        work_name_review: props.activeSupplier.work_name_review,
        work_address_review: props.activeSupplier.work_address_review,
        work_reg_number_review: props.activeSupplier.work_reg_number_review,
        work_fix_phone_review: props.activeSupplier.work_fix_phone_review,
        work_mobile_review: props.activeSupplier.work_mobile_review,
        contact_name_review: props.activeSupplier.contact_name_review,
        contact_NIC_review: props.activeSupplier.contact_NIC_review,
        contact_home_phone_review: props.activeSupplier.contact_home_phone_review,
        contact_mobile_phone_review: props.activeSupplier.contact_mobile_phone_review,
        contact_address_review: props.activeSupplier.contact_address_review,
        bank_name_review: props.activeSupplier.bank_name_review,
        bank_branch_review: props.activeSupplier.bank_branch_review,
        bank_account_review: props.activeSupplier.bank_account_review,
        bank_account_name_review: props.activeSupplier.bank_account_name_review,
        work_logo_review: props.activeSupplier.work_logo_review,
        contact_logo_review: props.activeSupplier.contact_logo_review,
        br_copy_review: props.activeSupplier.br_copy_review,
        tb_licence_copy_review: props.activeSupplier.tb_licence_copy_review,
        ins_copy_review: props.activeSupplier.ins_copy_review,
        billing_proof_review: props.activeSupplier.billing_proof_review,
        bank_name: props.activeSupplier.bank_name,
        bank_branch: props.activeSupplier.bank_branch,
        bank_account: props.activeSupplier.bank_account,
        bank_account_name: props.activeSupplier.bank_account_name,
        in_review_fields: props.activeSupplier.in_review_fields,
        rejected_fields: props.activeSupplier.rejected_fields,
        status: props.activeSupplier.status,
      }
    }

    return null;


  }


  handleFieldUpdate = (field, value) => {
    this.props.UpdateField({
      field: field,
      value: value,
      sid: this.state.id
    })
  }


  componentDidMount() {
    document.body.style.background = "#eee";
  }


  handleCatChange = (e, index) => {
    const item = e.target.id;
    const isChecked = e.target.checked;
    this.setState({
      ...this.state,
      categories: Object.values({
        ...this.state.categories,
        [index]: {
          ...this.state.categories[index],
          checked: isChecked
        }
      })
    }, () => {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(
        () => {
          this.saveAccount();
        }
        ,
        1000
      );

    });


  }

  handleChange = name => event => {

    this.setState({
      [name]: event.target.value,
    });
  };



  saveAccount = () => {

    this.props.updateUser(this.state);
  }


  uploadWorkLogo = (e) => {

    if (e.target.files[0] != null) {
      this.props.uploadWorkLogo(e.target.files[0]);
    }

  }


  uploadDocument = (e, type) => {

    if (e.target.files[0] != null) {
      this.props.uploadDocument(e.target.files[0], type);
    }

  }

  uploadPersonalLogo = (e) => {

    if (e.target.files[0] != null) {
      this.props.uploadPersonalLogo(e.target.files[0]);
    }

  }

  setCommission = () => {
    this.props.UpdateCommision(this.state.commission_rate, this.state.id);
  }

  handleCustomCommission = (e) => {
    this.setState({
      customcommision: e.target.checked
    });
  }

  handleUserBanned = (e) => {
    this.setState({
      ban_user: e.target.checked
    });
  }

  handleResponse = (status, field) => {
    this.props.UpdateFieldStatus(status, field, this.state.id);
  }


  render() {

    const { classes } = this.props;


    console.log(this.state)

    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer currentPage="account"></Drawer>
      <Alert />

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}>



        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff', width: 500, fontWeight: 600, fontSize: 16 }}>


                <h3> Supplier Details  </h3>
              </TableCell>

              <TableCell style={{ backgroundColor: 'white', width: 0, fontWeight: 600, fontSize: 15 }}>
                <Button style={{ background: "#f7a414", width: 150, marginLeft: 350 }} variant="contained" color="primary" component={Link} to="/Suppliers" className={classes.button}>Back</Button>
              </TableCell>



            </TableRow>




          </TableBody>
        </Table>







        <div className="pro-div" style={{ margin: 15 }}>
          <h5>Company Details</h5>
          <p>Please fill your company details correctly and provide at least two contact numbers.</p>
          <div className="profrm-min">
            <div className="profrm-div">

              <div className="profrm-row">


              </div>

              <div width="150%" style={{ width: '100%' }}>
                <div className="profrm-row">
                  <label>Supplier ID</label>
                  <input disabled={true} style={{ backgroundColor: 'rgba(202, 201, 201, 0.21)' }} type="text" name="id" value={this.props.activeSupplier.id} onChange={this.handleChange('id')} />
                </div>

                <div  >
                  <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.work_name_review} label="Company Name" style={{ fontFamily: 'Roboto , sans-serif', width: '1200px' }} rejected={this.state.rejected_fields.includes("work_name")} field='work_name' value={this.props.activeSupplier.work_name} onOK={(value) => this.handleFieldUpdate('work_name', value)} />
                </div>
                <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.work_reg_number_review} label="Company Register Number" value={this.props.activeSupplier.work_reg_number} rejected={this.state.rejected_fields.includes("work_reg_number")} field='work_reg_number' onOK={(value) => this.handleFieldUpdate('work_reg_number', value)} />

                <NewStaticTextBox validate="phone" onError={this.props.SnackBar} reviewAction={this.handleResponse} review={this.props.activeSupplier.work_fix_phone_review} label="Land Telephone" value={this.props.activeSupplier.work_fix_phone} rejected={this.state.rejected_fields.includes("work_fix_phone")} field='work_fix_phone' onOK={(value) => this.handleFieldUpdate('work_fix_phone', value)} />

                <NewStaticTextBox validate="phone" onError={this.props.SnackBar} reviewAction={this.handleResponse} review={this.props.activeSupplier.work_mobile_review} label="Mobile Telephone" value={this.props.activeSupplier.work_mobile} rejected={this.state.rejected_fields.includes("work_mobile")} field='work_mobile' onOK={(value) => this.handleFieldUpdate('work_mobile', value)} />

                <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.work_address_review} label="Address" style="width:50%;" value={this.props.activeSupplier.work_address} rejected={this.state.rejected_fields.includes("work_address")} field='work_address' onOK={(value) => this.handleFieldUpdate('work_address', value)} />
              </div>
            </div>
            <div className="profrm-up">
              {this.state.work_avatar != null ?


                (<div>
                  {this.state.work_logo_review === true && this.state.rejected_fields.includes("work_avatar") === false ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15, marginLeft: 75 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'work_logo_review')} >Approve</a>&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleResponse(0, 'work_logo_review')} >Reject</a>
                  </div>) : null}
                  {this.state.rejected_fields.includes("work_avatar") === true ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15, marginLeft: 75 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'work_logo_review')} >Approve</a>
                  </div>) : null}
                  <label htmlFor="file-work-logo" >
                    <div className="profrm-fil" style={{ backgroundImage: 'url(' + this.state.work_avatar + ')', cursor: 'pointer' }}  >
                      {/* <div >
                   
                        
                        <input
                                accept="image/*"
                                style={{ display: "none"}}
                                id="file-work-logo"
                                multiple
                                type="file"
                               
                                onChange ={(e)=>this.uploadWorkLogo(e,'work_avatar')}
                              />
                                 
                                <Button component="span" size="small" className={classes.button}>
                                
                                </Button>
                                <p style={{textAlign:'center', fontSize:11}}></p>
                              
                            
                      </div>
                    <span>Image size should be 210*210</span> */}
                    </div>
                  </label>
                </div>) : (


                  <div> <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="file-work-logo"
                    multiple
                    type="file"
                    onChange={this.uploadWorkLogo}
                  /> </div>



                )}
            </div>

          </div>
          <div className="clearfix"></div>
          <div className="profrm-row">
            <div className="pro-hit"></div>
            <h5>Contact Person Details</h5>
            <p>Contact person should be available to answer all phone calls and provide good customer support. </p>
          </div>
          <div class="profrm-min cp-min">
            <div class="profrm-div">
              <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.contact_name_review} label="Name" value={this.props.activeSupplier.contact_name} rejected={this.state.rejected_fields.includes("contact_name")} field='contact_name' onOK={(value) => this.handleFieldUpdate('contact_name', value)} />

              <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.contact_NIC_review} label="NIC Number" value={this.props.activeSupplier.contact_NIC} rejected={this.state.rejected_fields.includes("contact_NIC")} field='contact_NIC' onOK={(value) => this.handleFieldUpdate('contact_NIC', value)} />

              <NewStaticTextBox validate="phone" onError={this.props.SnackBar} reviewAction={this.handleResponse} review={this.props.activeSupplier.contact_home_phone_review} label="Home Telephone" value={this.props.activeSupplier.contact_home_phone} rejected={this.state.rejected_fields.includes("contact_home_phone")} field='contact_home_phone' onOK={(value) => this.handleFieldUpdate('contact_home_phone', value)} />

              <NewStaticTextBox validate="phone" onError={this.props.SnackBar} reviewAction={this.handleResponse} review={this.props.activeSupplier.contact_mobile_phone_review} label="Mobile Telephone" value={this.props.activeSupplier.contact_mobile_phone} rejected={this.state.rejected_fields.includes("contact_mobile_phone")} field='contact_mobile_phone' onOK={(value) => this.handleFieldUpdate('contact_mobile_phone', value)} />

              <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.contact_address_review} label="Address" value={this.props.activeSupplier.contact_address} rejected={this.state.rejected_fields.includes("contact_address")} field='contact_address' onOK={(value) => this.handleFieldUpdate('contact_address', value)} />
            </div>
          </div>

          <div className="profrm-up">
            {this.state.contact_avatar != null ?


              (<div>
                {this.state.contact_logo_review === true && this.state.rejected_fields.includes("contact_address") === false ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15, marginLeft: 75 }}>
                  <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'contact_logo_review')} >Approve</a>&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleResponse(0, 'contact_logo_review')} >Reject</a>
                </div>) : null}
                {this.state.rejected_fields.includes("contact_avatar") === true ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15, marginLeft: 75 }}>
                  <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'contact_logo_review')} >Approve</a>&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleResponse(0, 'contact_logo_review')} >Reject</a>
                </div>) : null}
                <label htmlFor="file-work-logo" >
                  <div className="profrm-fil" style={{ backgroundImage: 'url(' + this.state.contact_avatar + ')', cursor: 'pointer' }}  >
                    {/* <div >
                   
                        
                        <input
                                accept="image/*"
                                style={{ display: "none"}}
                                id="file-work-logo"
                                multiple
                                type="file"
                               
                            onChange ={(e)=>this.uploadPersonalLogo(e,'contact_avatar')}
                              />
                                 
                                <Button component="span" size="small" className={classes.button}>
                                
                                </Button>
                                <p style={{textAlign:'center', fontSize:11}}></p>
                              
                            
                      </div>
                    <span>Image size should be 210*210</span> */}
                  </div>
                </label>
              </div>) : (


                <div> <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="file-work-logo"
                  multiple
                  type="file"
                  onChange={this.uploadPersonalLogo}
                /> </div>



              )}
          </div>

          <div className="clearfix"></div>
          <div className="profrm-row">
            <div className="pro-hit"></div>
            <h5>Banking Details</h5>

          </div>
          <div class="profrm-min cp-min">
            <div class="profrm-div">
              <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.bank_name_review} label="Bank Name" value={this.props.activeSupplier.bank_name} rejected={this.state.rejected_fields.includes("bank_name")} field='bank_name' onOK={(value) => this.handleFieldUpdate('bank_name', value)} />

              <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.bank_branch_review} label="Branch" value={this.props.activeSupplier.bank_branch} rejected={this.state.rejected_fields.includes("bank_branch")} field='bank_branch' onOK={(value) => this.handleFieldUpdate('bank_branch', value)} />

              <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.bank_account_name_review} label="Account Name" value={this.props.activeSupplier.bank_account_name} rejected={this.state.rejected_fields.includes("bank_account_name")} field='bank_account_name' onOK={(value) => this.handleFieldUpdate('bank_account_name', value)} />

              <NewStaticTextBox reviewAction={this.handleResponse} review={this.props.activeSupplier.bank_account_review} label="Account Number" value={this.props.activeSupplier.bank_account} rejected={this.state.rejected_fields.includes("bank_account")} field='bank_account' onOK={(value) => this.handleFieldUpdate('bank_account', value)} />


            </div>
          </div>

          <div className="clearfix"></div>
          <div className="profrm-row">
            <div className="pro-hit"></div>
            <h5>Email &amp; Password</h5>

          </div>
          <div class="profrm-min cp-min">
            <div class="profrm-div">
              <NewStaticTextBox label="Email" verified={this.state.email_verified} value={this.props.activeSupplier.email} field='email' onOK={(value) => this.handleFieldUpdate('email', value)} />

              <NewStaticTextBox label="Password" type="password" field='password' onOK={(value) => this.handleFieldUpdate('password', value)} />




            </div>
          </div>




          <div className="clearfix"></div>


          <div className="profrm-btn">
            <input type="hidden" style={{ visible: false }} onClick={() => { this.saveAccount(); }} value="Update Profile" name="" />
          </div>


          <div className="clearfix"></div>
        </div>

        <div className="tucat" style={{ margin: 15 }}>

          <div>
            <h5>Tour Categories</h5>
            <p>Please select the tour categories that you mostly sell. This will help us to cross sell your products effectively.</p>
            <div className="tctchek">
              <div className="tctchek-chk">


                {this.state.categories.map((cat, index) => {
                  return <FormControlLabel

                    control={
                      <Checkbox name={cat.label} id={cat.id} color="primary" style={{ color: 'orange' }} checked={cat.checked} onChange={(e) => { this.handleCatChange(e, index) }}
                      />
                    }
                    label={cat.label} />
                })}

              </div>

              <div className="clearfix"></div>
            </div>

          </div>

          <div >

            <h5>Commission</h5>


            <div style={{ width: "590px" }}>
              <table width="100%">
                <tr>
                  <td width="150px">
                    <FormControlLabel
                      style={{ marginTop: 20 }}
                      control={
                        <Checkbox
                          checked={this.state.customcommision}
                          onChange={this.handleCustomCommission}
                          value="checkedB"
                          color="primary"
                          style={{ color: 'orange' }}
                        />
                      }
                      label="Custom rate"
                    />
                  </td>


                  <td width="185px" style={{ paddingTop: 25 }} className="profrm-row">
                    <input disabled={!this.state.customcommision} style={{ height: "30px", width: "170px", backgroundColor: 'rgba(202, 201, 201, 0.21)' }} type="text" name="id" value={this.state.commission_rate} onChange={this.handleChange('commission_rate')} />
                  </td>

                  <td>
                    <Button onClick={(e) => { this.setCommission() }} style={{ background: "#f7a414", width: 150, marginTop: 30 }} variant="contained" color="primary" className={classes.button}>Update</Button>
                  </td></tr></table>
            </div>

          </div>




          <div >

            <h5>Banned</h5>



            <div style={{ width: "590px" }}>
              <table width="100%">
                <tr>
                  <td width="150px" >
                    <FormControlLabel
                      style={{ marginTop: 20 }}
                      control={
                        <Checkbox
                          checked={this.state.ban_user}
                          onChange={this.handleUserBanned}
                          value="checkedB"
                          color="primary"
                          style={{ color: 'orange' }}
                        />
                      }
                      label="Ban user "
                    />
                  </td>

                  <td width="150px" style={{ paddingTop: 25 }} className="profrm-row" >

                    <input disabled={!this.state.ban_user} type="date" name="id" value={this.state.banned_till} onChange={this.handleChange('banned_till')} />
                  </td>

                  <td>
                    <Button onClick={(e) => { this.setBanned() }} style={{ background: "#f7a414", width: 150, marginTop: 30 }} variant="contained" color="primary" className={classes.button}>Update</Button>
                  </td></tr></table>
            </div>


          </div>





          <div >

            <h5>Upload Documents</h5>
            <p>Please upload scanned copies of all required company documents. Business registration or Guide license is mandatory.</p>
            <div className="uplo-doc">
              <div className="uplo-mun">


                {this.state.br_copy !== "" ? (<div>
                  {this.state.br_copy_review === true && this.state.rejected_fields.includes("br_copy") === false ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'br_copy_review')} >Approve</a>&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleResponse(0, 'br_copy_review')} >Reject</a>
                  </div>) : null}

                  {this.state.rejected_fields.includes("br_copy") === true ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'br_copy_review')} >Approve</a>
                  </div>) : null}

                  <div className="uplo-div added" style={{ backgroundImage: 'url(' + this.state.br_copy + ')' }}>
                    <div className="vop">
                      <a href={this.state.br_copy} target="_target" className="viw" ></a>
                      <span className="dele" onClick={() => this.props.UpdateRemoveAttachment('br_copy', this.state.id)}></span>
                    </div>
                  </div>
                  <p>Business Registration / Guide Licence*</p>
                  <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                </div>) : (
                  <div>
                    <div className="uplo-div">
                      <div className="bt"><input type="file" onChange={(e) => this.uploadDocument(e, 'br')} name="" /></div>
                    </div>
                    <p>Business Registration / Guide Licence*</p>
                    <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                  </div>
                )}


                {this.state.tb_licence_copy !== "" ? (<div>
                  {this.state.tb_licence_copy_review === true && this.state.rejected_fields.includes("tb_licence_copy") === false ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'tb_licence_copy_review')} >Approve</a>&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleResponse(0, 'tb_licence_copy_review')} >Reject</a>
                  </div>) : null}

                  {this.state.rejected_fields.includes("tb_licence_copy") === true ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'tb_licence_copy_review')} >Approve</a>
                  </div>) : null}
                  <div className="uplo-div added" style={{ backgroundImage: 'url(' + this.state.tb_licence_copy + ')' }}   >
                    <div className="vop">
                      <a href={this.state.tb_licence_copy} target="_target" className="viw" ></a>
                      <span className="dele" onClick={() => this.props.UpdateRemoveAttachment('tb_licence_copy', this.state.id)}></span>
                    </div>
                  </div>
                  <p>Tourist Board Licence</p>
                  <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                </div>) : (
                  <div>
                    <div className="uplo-div">
                      <div className="bt"><input type="file" onChange={(e) => this.uploadDocument(e, 'tbl')} name="" /></div>
                    </div>
                    <p>Tourist Board Licence</p>
                    <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                  </div>
                )}




                {this.state.ins_copy !== "" ? (<div>
                  {this.state.ins_copy_review === true && this.state.rejected_fields.includes("ins_copy") === false ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'ins_copy_review')}>Approve</a>&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleResponse(0, 'ins_copy_review')} >Reject</a>
                  </div>) : null}
                  {this.state.rejected_fields.includes("ins_copy") === true ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'ins_copy_review')}>Approve</a>
                  </div>) : null}
                  <div className="uplo-div added" style={{ backgroundImage: 'url(' + this.state.ins_copy + ')' }}   >
                    <div className="vop">
                      <a href={this.state.ins_copy} target="_target" className="viw" ></a>
                      <span className="dele" onClick={() => this.props.UpdateRemoveAttachment('ins_copy', this.state.id)}></span>
                    </div>
                  </div>
                  <p>Insurence</p>
                  <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                </div>) : (
                  <div>

                    <div className="uplo-div">
                      <div className="bt"><input type="file" onChange={(e) => this.uploadDocument(e, 'ins')} name="" /></div>
                    </div>
                    <p>Insurence</p>
                    <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                  </div>
                )}



                {this.state.billing_proof !== "" ? (<div>
                  {this.state.billing_proof_review === true && this.state.rejected_fields.includes("billing_proof") === false ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'billing_proof_review')} >Approve</a>&nbsp;&nbsp;<a style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.handleResponse(0, 'billing_proof_review')} >Reject</a>
                  </div>) : null}
                  {this.state.rejected_fields.includes("billing_proof") === true ? (<div style={{ fontSize: 12, display: 'block', marginTop: -15, marginBottom: 15 }}>
                    <a style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.handleResponse(1, 'billing_proof_review')} >Approve</a>
                  </div>) : null}
                  <div className="uplo-div added" style={{ backgroundImage: 'url(' + this.state.billing_proof + ')' }}   >
                    <div className="vop">
                      <a href={this.state.billing_proof} target="_target" className="viw" ></a>
                      <span className="dele" onClick={() => this.props.UpdateRemoveAttachment('billing_proof', this.state.id)}></span>
                    </div>
                  </div>
                  <p>Bank Statement</p>
                  <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                </div>) : (
                  <div>

                    <div className="uplo-div">
                      <div className="bt"><input type="file" onChange={(e) => this.uploadDocument(e, 'billing_proof')} name="" /></div>
                    </div>
                    <p>Bank Statement</p>
                    <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                  </div>
                )}




              </div>
              <div className="clearfix"></div>
              <div className="profrm-btn">
                {this.state.status === 0 || this.state.status === 3 ? (<input type="button" value="Approve profile" onClick={() => { this.props.UpdateStatus(1, this.state.id); }} name="" />) : null}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Account);