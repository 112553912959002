import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError,ShowRemark} from './CommonActions';
import store from '../store';
import axios from 'axios';



export function MyActivities(tt,ts,k,supplier,page,perPage) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/activities?tt='+tt+'&ts='+ts+'&k='+k+'&supplier='+supplier+'&page='+page+'&per_page='+perPage,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            //  fetch(API_URL+'/api/admin/activities',{
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActivities(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}





export function storeActivities(list) {
    return {
        type: 'STORE_ACTIVITIES',
        list: list
    }
}


export function GetActivity(activityID) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/activities?id='+activityID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActiveActivity(json));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

export function storeActiveActivity(act) {
    return {
        type: 'STORE_ACTIVE_ACTIVITY',
        activity: act
    }
}


export function LockActiveActivity(act) {
    return {
        type: 'LOCK_ACTIVE_ACTIVITY',        
    }
}


export function updateActivity(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(json.id)); 
                  dispatch(SnackBar(true,'Data saved','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}

export function UpdateActivityImageField(ActivityID,ImageId,Field,Value) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/activities/image/meta/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({
                id:ImageId,
                field:Field,
                value:Value
            }),            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(ActivityID)); 
                  dispatch(SnackBar(true,'Meta updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}


export function UpdateActivityField(ActivityID,Field,Value) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/activities/meta/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({
                id:ActivityID,
                field:Field,
                value:Value
            }),            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(ActivityID)); 
                  dispatch(SnackBar(true,'Tour meta updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}

export function UpdateActivityStatus(ActivityID,Status) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/activities/status?id='+ActivityID+'&status='+Status,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(ActivityID)); 
                  dispatch(SnackBar(true,'Tour status updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}


export function uploadPicture(data,fileName,type,aid){

    return function(dispatch) {
        dispatch(showUploadProgress(true,type));

        var fd = new FormData();
        fd.append('file',data);
        fd.append('name',fileName);
        fd.append('type',type);
        fd.append('aid',aid);

        axios.post(API_URL+'/api/supplier/activities/upload',fd,{            
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },           
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(GetActivity(aid));
              dispatch(SnackBar(true,'Image uploaded','success'));
              dispatch(showUploadProgress(false,type));
        }).catch((error)=>{
            console.log(error)              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could upload image','error'));
          dispatch(showUploadProgress(false,type));
        });
    }
}

export function showUploadProgress(s,imgType) {
    return {
        type: 'IMAGE_UPLOADING',
        status: s,
        varType:imgType+'Uploading'
    }
}


export function RemoveImage(ActivityID,ImageId) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Removing Image','success'));
        fetch(API_URL+'/api/supplier/activities/image/remove?id='+ImageId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetActivity(ActivityID)); 
                  dispatch(SnackBar(true,'Image removed','success'));                                                    
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}


export function ChangeStatus(data) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Updating status...','info'));
        fetch(API_URL+'/api/admin/activities/status',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(data.id));
                  dispatch(MyActivities(data.data.ttype,data.data.tsts,data.data.keyw,data.data.supplier,data.data.page,data.data.perPage)); 
                  dispatch(SnackBar(true,'Status Updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}

export function TrashTour(data){
    return function(dispatch){
        dispatch(ShowRemark(true,(data)=>{
            dispatch(ChangeStatus(data));
            dispatch(ShowRemark(false,null,null));
        },data))
    }
}

export function RejectTour(data){
    return function(dispatch){
        dispatch(ShowRemark(true,(data)=>{
            dispatch(ChangeStatus(data));
            dispatch(ShowRemark(false,null,null));
        },data))
    }
}

export function AddRemark(data) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Updating status...','info'));
        fetch(API_URL+'/api/admin/activities/remark',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(data.id)); 
                  dispatch(SnackBar(true,'Status Updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}

export function ReviewSubmit(data) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Submitting for review','info'));
        fetch(API_URL+'/api/supplier/activities/review',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(data.id)); 
                  dispatch(SnackBar(true,'Submit complete!','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}



export function SortImages(ActivityID,data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities/image/sort',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({images:data}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(ActivityID));
                  dispatch(SnackBar(true,'Images updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}


export function DeleteActivity(activityID,tt,ts,k) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/activities/delete?id='+activityID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(MyActivities(tt,ts,k));
                  dispatch(SnackBar(true,'Tour deleted','success'));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

export function FetchPolicies(type) {
    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/settings/site/policy/get?type='+type,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storePolicies(json,type));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   
}

export function storePolicies(list,pType) {
    return {
        type: 'STORE_POLICIES',
        list: list,
        policyType:pType
    }
}


export function featuredUpdating(bool) { return { type: 'FEATURED_TOUR_UPDATE', bool } }

export function featuredToursList(type, aktivity) {
    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'updating...','info'));
        fetch(API_URL+'/api/admin/activities/featured',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(type)
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(MyActivities(aktivity.tt, aktivity.ts, aktivity.k, aktivity.supplier, aktivity.page, aktivity.perPage));                   
                  dispatch(featuredUpdating(true));  
                  dispatch(SnackBar(true,'Tour updated','success'));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   
}